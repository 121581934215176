import React from "react"
import { css } from "@emotion/core"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import List from "components/UI/List"
import { CardWrapper } from "components/UI/Card"
import InternalLink from "components/UI/InternalLink"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import ColumnTitle from "components/UI/ColumnTitle"
import RichText from "components/UI/RichText"
import sort from "utils/sort"
import linkResolver from "utils/linkResolver"
import t from "theme"

//uses GraphQL back reference - which automatically links articles back to company
export const query = graphql`
    query($ticker: String!) {
        selectedCompany: contentfulCompany(ticker: { eq: $ticker }) {
            ...CompanyFields
            article {
                ...ArticleContent
            }
            question {
                ...QuestionContent
            }
        }
    }
`

const CompanyOverview = ({ pageContext: { commonData, ticker }, data: { selectedCompany }, location }) => {
    const featuredArticles = getFeaturedArticles(commonData, ticker)
    const articles = selectedCompany.article
    const questions = selectedCompany.question

    return (
        <MainLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature="Overview"
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title={"FEATURED ARTICLES"} featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={selectedCompany.shortName + " Articles"}
                pageDescription={"The best research articles on " + selectedCompany.shortName}
                pagePathname={location.pathname}
            />

            <div>
                <ColumnTitle>{"OVERVIEW OF " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>

                <RichText
                    body={selectedCompany.overview.json}
                    linkUrl={"/stock/" + selectedCompany.ticker.toLowerCase()}
                />
            </div>

            {articles ? (
                <div
                    css={css`
                        margin-top: ${t.space[7]};
                    `}>
                    <ColumnTitle>{"LATEST ARTICLES ON " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>

                    <List
                        items={articles.sort(sort("sortTimestamp", "desc")).map((article, index) => (
                            <CardWrapper>
                                <InternalLink
                                    headline={article.headline}
                                    type="small"
                                    date={article.sortTimestamp}
                                    pathname={linkResolver({ slug: article.slug, type: "article" })}
                                />
                            </CardWrapper>
                        ))}
                        space={t.space[3]}
                        nodivider
                    />
                </div>
            ) : null}

            {questions ? (
                <div
                    css={css`
                        margin-top: ${t.space[7]};
                    `}>
                    <ColumnTitle>{"LATEST QUESTIONS ON " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>

                    <List
                        items={questions.sort(sort("sortTimestamp", "desc")).map((question, index) => (
                            <CardWrapper>
                                <InternalLink
                                    headline={question.headline}
                                    type="small"
                                    date={question.sortTimestamp}
                                    pathname={linkResolver({ slug: question.slug, type: "question" })}
                                />
                            </CardWrapper>
                        ))}
                        space={t.space[3]}
                        nodivider
                    />
                </div>
            ) : null}
        </MainLayout>
    )
}

export default CompanyOverview
